import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import useBreakpoint from '../../../hooks/useBreakpoint'

import Typography from '../../common/Typography'

import ShieldIcon from '../../../assets/images/services/services_icon_shield.svg'
import InfinityIcon from '../../../assets/images/services/services_icon_infinity.svg'
import ResearchIcon from '../../../assets/images/services/services_icon_research.svg'
import BannerImageDesktopWebP from '../../../assets/images/services/services_banner_desktop.webp'
import BannerImageDesktop from '../../../assets/images/services/services_banner_desktop.png'
import BannerImageMobileWebP from '../../../assets/images/services/services_banner_mobile.webp'
import BannerImageMobile from '../../../assets/images/services/services_banner_mobile.png'

export default function ServicesBanner(): ReactElement {
  const { t } = useTranslation()
  const isDesktop = useBreakpoint('sm')

  const BannerData = [
    {
      tileIndex: 0,
      icon: ShieldIcon,
      title: 'services.banner.tile1.title',
      text: 'services.banner.tile1.description',
    },
    {
      tileIndex: 1,
      icon: ResearchIcon,
      title: 'services.banner.tile2.title',
      text: 'services.banner.tile2.description',
    },
    {
      tileIndex: 2,
      icon: InfinityIcon,
      title: 'services.banner.tile3.title',
      text: 'services.banner.tile3.description',
    },
  ]

  const BannerImage = {
    desktop: {
      webPUrl: BannerImageDesktopWebP,
      fallbackUrl: BannerImageDesktop,
    },
    mobile: {
      webPUrl: BannerImageMobileWebP,
      fallbackUrl: BannerImageMobile,
    },
  }

  return (
    <StyledServicesBannerContainer>
      <StyledBannerImageContainer>
        <picture>
          <source
            srcSet={
              isDesktop
                ? BannerImage.desktop.webPUrl
                : BannerImage.mobile.webPUrl
            }
            type="image/webp"
          />
          <source
            srcSet={
              isDesktop
                ? BannerImage.desktop.fallbackUrl
                : BannerImage.mobile.fallbackUrl
            }
            type="image/png"
          />
          <StyledBannerImage
            alt="Services Banner"
            src={
              isDesktop
                ? BannerImage.desktop.fallbackUrl
                : BannerImage.mobile.fallbackUrl
            }
            width={isDesktop ? '1280' : '750'}
            height={isDesktop ? '550' : '1100'}
          />
        </picture>
      </StyledBannerImageContainer>
      <StyledServicesTiles>
        {BannerData.map(tile => {
          return (
            <StyledServiceTile key={uuidv4()}>
              <StyledServiceIcon>
                <img
                  src={tile.icon}
                  alt="Service Icon Support"
                  height="50"
                  width="50"
                />
              </StyledServiceIcon>
              <StyledServiceTitle>{t(tile.title)}</StyledServiceTitle>
              <Typography type="copyBig">{t(tile.text)}</Typography>
            </StyledServiceTile>
          )
        })}
      </StyledServicesTiles>
    </StyledServicesBannerContainer>
  )
}

const StyledServicesBannerContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 0 0 -15rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 100%;
    margin: 0 0 -5rem;
  }
`
const StyledBannerImageContainer = styled.div`
  position: relative;
  display: block;
  width: 100vw;
  max-width: 128rem;
  align-self: center;
  border-radius: 4rem;
  background-color: transparent;
  margin: 0 auto;
`
const StyledBannerImage = styled.img`
  width: 100%;
  max-width: 144rem;
  height: auto;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    border-radius: 4rem;
  }
`

const StyledServicesTiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  position: relative;
  top: -20rem;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.palette.global.white};
  border-radius: 2rem;
  box-shadow: 0px 1.3rem 4rem 0.5rem rgba(116, 134, 169, 0.2);
  padding: 2.5rem 2rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    flex-direction: row;
    justify-content: space-evenly;
    top: -10rem;
    width: 95%;
    max-width: 112rem;
    padding: 0;
  }
`

const StyledServiceTile = styled.div`
  position: relative;
  width: 100%;
  margin: 0rem auto;
  text-align: center;
  padding: 1.5rem 1.5rem 3rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 33%;
    max-width: 27rem;
    margin: 6rem 2rem;
    padding: 0;
  }
`

const StyledServiceIcon = styled.div`
  text-align: center;
`
const StyledServiceTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 700;
  margin: 1rem 0;
  max-width: initial;
  color: ${({ theme }) => theme.palette.global.accent};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    max-width: 30rem;
  }
`
