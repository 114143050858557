import styled from 'styled-components/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useBreakpoint from '../../../hooks/useBreakpoint'
import HeroScene from '../../../components/common/Hero/HeroScene'
import ServiceJourneyHeader from './ServiceJourneyHeader'
import ServiceJourneyCard from './ServiceJourneyCard'
import DesktopBackground from '../../../assets/images/services/service_journey_background_desktop.svg'
import MobileBackground from '../../../assets/images/services/service_journey_background_mobile.svg'
import PlanetService from '../../common/Hero/PlanetService'

export default function ServiceJourney() {
  const { t } = useTranslation()
  const isDesktop = useBreakpoint('md')

  const planetServiceModel = (
    <PlanetService
      scale={isDesktop ? [1, 1, 1] : [1.2, 1.2, 1.2]}
      position={isDesktop ? [0, 0, 0] : [9, -12, 0]}
      rotation={[0.4, -0.8, 0.4]}
    />
  )

  return (
    <StyledContentContainer>
      <StyledHeroSceneWrapper>
        <HeroScene
          threeModel={planetServiceModel}
          left={isDesktop ? '19%' : '0'}
          top={isDesktop ? '0' : '15%'}
          height={isDesktop ? '100rem' : '50rem'}
        />
      </StyledHeroSceneWrapper>
      <ServiceJourneyHeader
        title={t('services.journey.title')}
        copy={t('services.journey.copy')}
      />
      <StyledJourneyContainer>
        <StyledServiceJourneyRoadContainer>
          {isDesktop ? (
            <StyledBackgroundImage src={DesktopBackground} />
          ) : (
            <StyledBackgroundImage src={MobileBackground} />
          )}
          <ServiceJourneyCard
            title={t('services.journey.blocks.service1.title')}
            copy={t('services.journey.blocks.service1.copy')}
            color="#2486C4"
            labels={[
              t('services.journey.blocks.service1.labels.label1'),
              t('services.journey.blocks.service1.labels.label2'),
              t('services.journey.blocks.service1.labels.label3'),
              t('services.journey.blocks.service1.labels.label4'),
            ]}
            position={{ x: '11.3rem', y: '0' }}
          />
          <ServiceJourneyCard
            title={t('services.journey.blocks.service2.title')}
            copy={t('services.journey.blocks.service2.copy')}
            color="#C22435"
            labels={[
              t('services.journey.blocks.service2.labels.label1'),
              t('services.journey.blocks.service2.labels.label2'),
              t('services.journey.blocks.service2.labels.label3'),
              t('services.journey.blocks.service2.labels.label4'),
            ]}
            position={{ x: '58.3rem', y: '21.1rem' }}
          />
          <ServiceJourneyCard
            title={t('services.journey.blocks.service3.title')}
            copy={t('services.journey.blocks.service3.copy')}
            color="#EC7E54"
            labels={[
              t('services.journey.blocks.service3.labels.label1'),
              t('services.journey.blocks.service3.labels.label2'),
              t('services.journey.blocks.service3.labels.label3'),
              t('services.journey.blocks.service3.labels.label4'),
              t('services.journey.blocks.service3.labels.label5'),
            ]}
            position={{ x: '0', y: '35.4rem' }}
          />
          <ServiceJourneyCard
            title={t('services.journey.blocks.service4.title')}
            copy={t('services.journey.blocks.service4.copy')}
            color="#F6BB5D"
            labels={[
              t('services.journey.blocks.service4.labels.label1'),
              t('services.journey.blocks.service4.labels.label2'),
              t('services.journey.blocks.service4.labels.label3'),
              t('services.journey.blocks.service4.labels.label4'),
              t('services.journey.blocks.service4.labels.label5'),
            ]}
            position={{ x: '45.3rem', y: '59.5rem' }}
          />
        </StyledServiceJourneyRoadContainer>
      </StyledJourneyContainer>
    </StyledContentContainer>
  )
}

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 113.2rem;
`

const StyledHeroSceneWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.queries.sm} {
    transform: scale(1.2) translate(3.5rem, -10rem);
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    transform: none;
  }
`

const StyledJourneyContainer = styled.div`
  position: relative;
  margin: 0 0 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.queries.md} {
    margin: 0 0 21.4rem;
    transform: translateX(3.5rem);
  }
`

const StyledServiceJourneyRoadContainer = styled.div`
  width: 100%;
  max-width: 100rem;
  position: relative;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.queries.md} {
    padding: 0;
    display: block;
  }
`

const StyledBackgroundImage = styled.img`
  width: 40.1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -4rem;
  z-index: -1;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    top: -6rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    position: relative;
    margin-top: 4rem;
    height: auto;
    width: 100%;
  }
`
