import { Canvas } from '@react-three/fiber'
import React, { ReactElement, Suspense } from 'react'
import styled from 'styled-components/macro'
import { NextGenImage } from '../../../interfaces/common'
import { OrbitControls } from '@react-three/drei'

interface HeroSceneProps {
  threeModel: ReactElement
  left?: string
  top?: string
  height?: string
}

export default function HeroScene({
  threeModel,
  left,
  top,
  height,
}: HeroSceneProps): ReactElement {
  return (
    <>
      <StyledCanvasWrapper left={left} top={top} height={height}>
        <Canvas
          camera={{
            position: [0, 0, 90],
            fov: 400,
            near: 0.01,
            frustumCulled: false,
          }}
          onCreated={({ gl }) => gl.setClearColor(0xffffff, 0)}
          dpr={
            typeof window !== 'undefined' ? window.devicePixelRatio : undefined
          }
          linear
        >
          <Suspense fallback={null}>
            {threeModel}
            <ambientLight intensity={1.5} />
            <pointLight position={[30, 10, 0]} color={0x00ffff} />
          </Suspense>
        </Canvas>
      </StyledCanvasWrapper>
    </>
  )
}

const StyledCanvasWrapper = styled.div<{
  left: string | undefined
  top: string | undefined
  height: string | undefined
}>`
  position: absolute;
  z-index: 0;
  right: 0;
  height: ${({ height }) => height};
  left: ${({ left }) => left};
  width: 100%;
  max-width: 100vw;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    top: -5rem; /* to prevent the laptop to get cut  */
  }

  > div {
    overflow: visible !important;
  }

  canvas {
    position: absolute;
    top: ${({ top }) => top};
  }
`
