import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import { DreiGLTF } from '../../../interfaces/three'
import { useFrame } from '@react-three/fiber'

export default function PlanetReference({
  ...props
}: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/models/glb/HeroPlanet.glb') as DreiGLTF
  const planetRef = useRef<THREE.Group>()

  useFrame(() => {
    if (planetRef.current) {
      planetRef.current.rotation.y += 0.001
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={planetRef}>
        <group name="Armature_Planet_01">
          <primitive object={nodes.Root_1} />
          <skinnedMesh
            name="Planet_L_1"
            frustumCulled={false}
            geometry={nodes.Planet_L_1.geometry}
            material={nodes.Planet_L_1.material}
            skeleton={nodes.Planet_L_1.skeleton}
          />
          <skinnedMesh
            name="Roads_R"
            frustumCulled={false}
            geometry={nodes.Roads_R.geometry}
            material={nodes.Roads_R.material}
            skeleton={nodes.Roads_R.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_R"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_R.geometry}
            material={nodes.StaticBuildings_R.material}
            skeleton={nodes.StaticBuildings_R.skeleton}
          />
          <skinnedMesh
            name="Trees_R"
            frustumCulled={false}
            geometry={nodes.Trees_R.geometry}
            material={nodes.Trees_R.material}
            skeleton={nodes.Trees_R.skeleton}
          />
          <skinnedMesh
            name="Planet_R_1"
            frustumCulled={false}
            geometry={nodes.Planet_R_1.geometry}
            material={nodes.Planet_R_1.material}
            skeleton={nodes.Planet_R_1.skeleton}
          />
          <skinnedMesh
            name="Roads_L"
            frustumCulled={false}
            geometry={nodes.Roads_L.geometry}
            material={nodes.Roads_L.material}
            skeleton={nodes.Roads_L.skeleton}
          />
          <skinnedMesh
            name="Rocket"
            frustumCulled={false}
            geometry={nodes.Rocket.geometry}
            material={materials.M_Rocket}
            skeleton={nodes.Rocket.skeleton}
          />
          <skinnedMesh
            name="Rocket_Holder"
            frustumCulled={false}
            geometry={nodes.Rocket_Holder.geometry}
            material={materials.M_MechanicalArm}
            skeleton={nodes.Rocket_Holder.skeleton}
          />
          <skinnedMesh
            name="RocketWindows"
            frustumCulled={false}
            geometry={nodes.RocketWindows.geometry}
            material={materials.M_RocketWindow}
            skeleton={nodes.RocketWindows.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_L"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_L.geometry}
            material={nodes.StaticBuildings_L.material}
            skeleton={nodes.StaticBuildings_L.skeleton}
          />
          <skinnedMesh
            name="Trees_L"
            frustumCulled={false}
            geometry={nodes.Trees_L.geometry}
            material={nodes.Trees_L.material}
            skeleton={nodes.Trees_L.skeleton}
          />
        </group>
        <group name="Armature_Planet_02">
          <primitive object={nodes.Root_02} />
        </group>

        <group name="Armature_Icons">
          <primitive object={nodes.Root_2} />
          <skinnedMesh
            name="Icon012"
            frustumCulled={false}
            geometry={nodes.Icon012.geometry}
            material={materials.M_Coins}
            skeleton={nodes.Icon012.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
