import styled from 'styled-components/macro'
import Arrow from '../../common/Arrows/ArrowRight'
import React, { ReactComponentElement } from 'react'
import useBreakpoint from '../../../hooks/useBreakpoint'

type Props = {
  title: string
  copy: string
  link: string
  linkText: string
  color: string
  alignment: 'left' | 'right'
  image: string
  imageWidth: string
}

export default function ServiceDeepDiveBanner(props: Props) {
  const { title, copy, link, linkText, color, alignment, image, imageWidth } =
    props
  const isDesktop = useBreakpoint('sm')

  return (
    <StyledBannerContainer
      style={{
        flexDirection: isDesktop
          ? alignment === 'left'
            ? 'row'
            : 'row-reverse'
          : 'column',
      }}
    >
      <StyledImageContainer alignment={alignment} color={color}>
        <StyledBannerImage
          src={image}
          alignment={alignment}
          imageWidth={imageWidth}
        />
      </StyledImageContainer>
      <StyledTextContainer>
        <StyledBannerTitle style={{ color }}>{title}</StyledBannerTitle>
        <StyledBannerCopy>{copy}</StyledBannerCopy>
        <StyledBannerLink style={{ color }} href={link} target="_blank">
          {linkText}
          <Arrow color={color} />
        </StyledBannerLink>
      </StyledTextContainer>
    </StyledBannerContainer>
  )
}

const StyledBannerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 3.5rem;
  box-shadow: 0 1.3rem 4rem 0.5rem #7486a933;
  display: flex;
  margin: 0 0 5rem 0;
  z-index: 2;
`

const StyledTextContainer = styled.div`
  padding: 4rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    height: 24rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    height: 27rem;
  }
`

const StyledBannerTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
  text-align: left;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 40rem;
  }
`

const StyledBannerCopy = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  margin: 0 0 2rem 0;
  text-align: left;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 36rem;
  }
`

const StyledBannerLink = styled.a`
  text-align: left;
  text-decoration: underline;
  font-weight: 700;

  svg {
    margin-left: 1.2rem;
  }

  ${({ theme }) => theme.breakpoints.queries.sm} {
    :hover {
      svg {
        animation-name: Arrow-Right-Animation;
        animation-duration: 0.6s;
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }
    }
  }
`

const StyledImageContainer = styled.div<{
  alignment: string
  color: string
}>`
  width: 100%;
  height: 26.5rem;
  position: relative;
  background-color: ${({ color }) => color};
  border-radius: 3.5rem 3.5rem 0 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 26.5rem;
    height: auto;
    flex: 1 1 50%;
    border-radius: ${({ alignment }) =>
      alignment === 'left' ? '3.5rem 0 0 3.5rem' : '0 3.5rem 3.5rem 0'};
  }
`

const StyledBannerImage = styled.img<{
  alignment: string
  imageWidth: string
}>`
  position: absolute;
  height: 110%;
  inset: auto auto 0 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    height: auto;
    width: ${({ imageWidth }) => imageWidth};
    inset: ${({ alignment }) =>
      alignment === 'left' ? 'auto 1rem 0 auto' : 'auto auto 1rem 0'};
    border-radius: ${({ alignment }) =>
      alignment === 'left' ? '3.5rem 0 0 3.5rem' : '0 3.5rem 3.5rem 0'};
  }
`
