import styled from 'styled-components/macro'
import React from 'react'

type Props = {
  title: string
  copy: string
}

export default function ServiceJourneyHeader(props: Props) {
  const { title, copy } = props

  return (
    <StyledJourneyHeaderContainer>
      <StyledCardTitle>{title}</StyledCardTitle>
      <StyledCardCopy>{copy}</StyledCardCopy>
    </StyledJourneyHeaderContainer>
  )
}

const StyledJourneyHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 5.6rem 3.8rem 18rem 3.8rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto 7rem 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 5.3rem;
    line-height: 6.5rem;
    padding: 10rem 0 8rem 0;
    margin: 0 auto 7rem 5rem;
    width: 36rem;
  }
`

const StyledCardTitle = styled.h2`
  font-size: 3.3rem;
  line-height: 3.8rem;
  font-weight: 500;
  margin: 0 0 2.7rem 0;
  width: 31rem;
  color: #596e81;
  text-align: left;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: auto;
    font-size: 5.3rem;
    line-height: 6.5rem;
  }
`

const StyledCardCopy = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: left;
  color: #3b3b3b;
  width: 32rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    text-align: left;
  }
`
