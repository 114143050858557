import styled from 'styled-components/macro'
import React from 'react'
import ServiceDeepDiveBanner from './ServiceDeepDiveBanner'
import { useTranslation } from 'react-i18next'
import DirectusBannerImage from '../../../assets/images/services/directus.png'
import ScrollytellingBannerImage from '../../../assets/images/services/pete.png'
import GamificationBannerImage from '../../../assets/images/services/gamification.png'

export default function ServiceDeepDive() {
  const { t } = useTranslation()

  const showScrollytelling = false

  return (
    <StyledDeepDiveContainer>
      <StyledDeepDiveTitle>{t('services.deep_dive.title')}</StyledDeepDiveTitle>
      <StyledDeepDiveCopy>{t('services.deep_dive.copy')}</StyledDeepDiveCopy>
      <ServiceDeepDiveBanner
        title={t('services.deep_dive.banner.banner1.title')}
        copy={t('services.deep_dive.banner.banner1.copy')}
        link="https://gamification.poprocket.com/"
        linkText={t('services.deep_dive.banner.banner1.link_text')}
        color="#EC7E54"
        alignment="left"
        image={GamificationBannerImage}
        imageWidth="120%"
      />
      <ServiceDeepDiveBanner
        title={t('services.deep_dive.banner.banner2.title')}
        copy={t('services.deep_dive.banner.banner2.copy')}
        link="https://why-headless.poprocket.com/"
        linkText={t('services.deep_dive.banner.banner2.link_text')}
        color="#2486C4"
        alignment="right"
        image={DirectusBannerImage}
        imageWidth="150%"
      />
      {showScrollytelling && (
        <ServiceDeepDiveBanner
          title={t('services.deep_dive.banner.banner3.title')}
          copy={t('services.deep_dive.banner.banner3.copy')}
          link="link"
          linkText={t('services.deep_dive.banner.banner3.link_text')}
          color="#F6BB5D"
          alignment="left"
          image={ScrollytellingBannerImage}
          imageWidth="100%"
        />
      )}
    </StyledDeepDiveContainer>
  )
}

const StyledDeepDiveTitle = styled.h2`
  font-size: 3.3rem;
  line-height: 3.8rem;
  font-weight: 500;
  margin: 0 0 2.7rem 0;
  color: #f6f6f6;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 5.3rem;
    line-height: 6.5rem;
  }
`

const StyledDeepDiveCopy = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  color: #f6f6f6;
  margin: 0 0 4.5rem 0;
  width: 30rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 31rem;
    margin: 0 0 5.5rem 0;
  }
`

const StyledDeepDiveContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 37.5rem;
  margin: 0 0 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2.2rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 68.6rem;
    max-width: none;
    padding: 0;
    margin: 0 0 14rem 0;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    width: 80.3rem;
  }
`
