import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/SEO/SEO'
import ServicesBanner from '../components/Services/ServicesBanner/ServicesBanner'
import ServiceJourney from '../components/Services/ServiceJourney/ServiceJourney'
import ServiceDeepDive from '../components/Services/ServiceDeepDives/ServiceDeepDive'

export default function Services() {
  return (
    <>
      <SEO
        title="Pop Rocket | Das sind unsere Leistungen"
        description="Wir sind eine Fullservice Digitalagentur. Wir begleiten unsere Kunden von der ersten Idee bis weit nach dem Rollout. Partnerschaftlich. Auf Augenhöhe. Langfristig."
        tabTitle="Leistungen"
      />
      <Layout overflow="auto" hasGradient>
        <ServiceJourney />
        <ServiceDeepDive />
        <ServicesBanner />
      </Layout>
    </>
  )
}
