import styled from 'styled-components/macro'
import React from 'react'

type Props = {
  title: string
  copy: string
  labels: string[]
  color: string
  position: { x: string; y: string }
}

export default function ServiceJourneyCard(props: Props) {
  const { title, copy, labels, color, position } = props

  return (
    <StyledCardContainer style={{ left: position.x, top: position.y }}>
      <StyledCardTitle style={{ color }}>{title}</StyledCardTitle>
      <StyledCardCopy>{copy}</StyledCardCopy>
      <StyledCardLabelContainer>
        {labels.map(label => (
          <StyledLabel style={{ backgroundColor: color }}>{label}</StyledLabel>
        ))}
      </StyledCardLabelContainer>
    </StyledCardContainer>
  )
}

const StyledCardContainer = styled.div`
  position: static;
  width: 100%;
  background-color: white;
  border-radius: 3.5rem;
  padding: 3rem;
  box-shadow: 0 1.3rem 4rem 0.5rem #7486a933;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 0 5rem 0;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 44.6rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    position: absolute;
    width: 34.6rem;
    margin: 0;
  }
`

const StyledCardTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
`

const StyledCardCopy = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  margin: 0 0 2rem 0;
  text-align: left;
`

const StyledCardLabelContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const StyledLabel = styled.div`
  padding: 0.5rem 1.2rem;
  border-radius: 4rem;
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
`
